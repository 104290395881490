.my-div-icon {
    width: 20px;
    height: 20px;
    background: blue;
    border-radius: 20px;
}

.out-cut-icon {
    width: 20px;
    height: 20px;
    background: #ff5a5a;
    border-radius: 20px;
}
